<template>
<div class="container-fluid">
    <section class="content" v-if="datos">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>{{$t('general.companiacorreduria')}}</h1>
          </div>
          <div class="col-sm-6">
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
        <div class="row">
            <!-- Ocultar esta columna en la creación de una compañía -->
            <div class="col-md-2">
                <!-- Datos Compañía -->
                <div class="card bg-gradient-primary">
                    <div class="card-body box-profile">
                        <h3 class="profile-username text-center">{{ this.titulo }} </h3>
                        <p class="text-muted text-center text-white">{{ this.prefijo }} </p>                
                    </div>
                </div>
                <div class="card card-primary card-outline">
                    <div class="card-body box-profile">
                        <ul class="list-group list-group-unbordered mb-3">
                        <li class="list-group-item">
                            <b>{{ $t('general.activos') }}</b> <a class="float-right">{{ numExpedientesActivos }}</a>
                        </li>
                        <li class="list-group-item">
                            <b>{{ $t('general.facturar') }}</b> <a class="float-right">{{ numExpedientesFacturar }}</a>
                        </li>
                        <li class="list-group-item">
                            <b>{{ $t('general.finalizados') }}</b> <a class="float-right">{{ numExpedientesFinalizados }}</a>
                        </li>
                        </ul>
                    </div>
                </div>
                <div class="callout callout-info">
                  <h5>{{ $t('general.ultimamodificacion') }}</h5>
                  <p>{{ datos.datos.fecha_actualiza_companyia.slice(8,10)}}-{{ datos.datos.fecha_actualiza_companyia.slice(5,7)}}-{{ datos.datos.fecha_actualiza_companyia.slice(0,4)}} |{{ datos.datos.fecha_actualiza_companyia.slice(10,16) }}</p>
                </div>
            </div>
            <div class="col-md-10">
                    <div class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                        <ul class="nav nav-pills">
                            <li class="nav-item"><a class="nav-link active" href="#datos_compania" data-toggle="tab">{{ $t('general.datos') }}</a></li>                            
                            <li class="nav-item" v-if="editandoCompanyia"><a class="nav-link" href="#sistema_compania" data-toggle="tab">{{ $t('general.sistema') }}</a></li>
                            <li class="nav-item" v-if="editandoCompanyia"><a class="nav-link" href="#facturacion_compania" data-toggle="tab">{{ $t('general.facturacion') }}</a></li>
                            <li class="nav-item" v-if="editandoCompanyia"><a class="nav-link" href="#especialidades_compania" data-toggle="tab">{{ $t('general.especialidades') }}</a></li>
                            <li class="nav-item" v-if="editandoCompanyia && baremosact"><a class="nav-link" href="#baremos_compania" data-toggle="tab">{{ $t('general.baremos') }}</a></li>
                            <li class="nav-item" v-if="editandoCompanyia"><a class="nav-link" href="#companias_seguros_compania" data-toggle="tab">{{ $t('general.companiasseguros') }}</a></li>
                            <li class="nav-item" v-if="editandoCompanyia"><a class="nav-link" href="#slas_compania" data-toggle="tab">SLAs</a></li>
                            <li class="nav-item" v-if="editandoCompanyia"><a class="nav-link" href="#guardias_compania" data-toggle="tab">{{ $t('general.guardias') }}</a></li>
                            <li class="nav-item" v-if="editandoCompanyia"><a class="nav-link" href="#tramitadores_compania" data-toggle="tab">{{ $t('general.tramitadores') }}</a></li>
                            <li class="nav-item" v-if="editandoCompanyia"><a class="nav-link" href="#emails_compania" data-toggle="tab">Emails</a></li>
                            <li class="nav-item" v-if="editandoCompanyia"><a class="nav-link" href="#centralita_compania" data-toggle="tab">{{ $t('general.centralita') }}</a></li>
                        </ul>                        
                    </div>
                    </div>
                        <div class="tab-content">
                            <div class="active tab-pane" id="datos_compania">
                                <compania-datos v-if="editandoCompanyia" :datos="datos.datos" :id="id"/>
                            </div>
                            <div class="tab-pane" id="sistema_compania" v-if="editandoCompanyia">
                                <compania-sistema :datos="datos.datos" />
                            </div>
                            <div class="tab-pane" id="especialidades_compania" v-if="editandoCompanyia">
                                <compania-especialidades :datos="datos.datos" />
                            </div> 
                            <div class="tab-pane" id="facturacion_compania" v-if="editandoCompanyia">
                                <compania-facturacion :datos="datos.datos"/>
                            </div>                           
                            <div class="tab-pane" id="baremos_compania" v-if="editandoCompanyia">
                                <compania-baremos />
                            </div>
                            <div class="tab-pane" id="companias_seguros_compania" v-if="editandoCompanyia">
                                <compania-seguros :datos="datos.datos"/>
                            </div>
                            <div class="tab-pane" id="slas_compania" v-if="editandoCompanyia">
                                <compania-slas />
                            </div>
                            <div class="tab-pane" id="guardias_compania" v-if="editandoCompanyia">
                                <compania-guardias :datos="datos.datos"/>
                            </div>
                            <div class="tab-pane" id="tramitadores_compania" v-if="editandoCompanyia">
                                <compania-tramitadores :id="id"/>
                            </div>
                            <div class="tab-pane" id="emails_compania" v-if="editandoCompanyia">
                                <compania-emails :mails="datos.datos?.emails"/>
                            </div>
                            <div class="tab-pane" id="centralita_compania" v-if="editandoCompanyia">
                                <compania-centralita />
                            </div>
                        </div>
            </div>
        </div>
    </section>
    <section class="content" v-else>
        <h1>{{ $t('general.nuevacompania') }}</h1>
        <compania-datos />
    </section>
</div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi.js'
import CompaniaDatos from './datos/CompaniaDatos.vue'
import CompaniaSistema from './sistema/CompaniaSistema.vue'
import CompaniaEspecialidades from './especialidades/CompaniaEspecialidades.vue'
import CompaniaFacturacion from './facturacion/CompaniaFacturacion.vue'
import CompaniaCentralita from './CompaniaCentralita.vue' 
import CompaniaEmails from './emails/CompaniaEmails.vue'  
import CompaniaTramitadores from './CompaniaTramitadores.vue' 
import CompaniaGuardias from './guardias/CompaniaGuardias.vue' 
import CompaniaSlas from './CompaniaSlas.vue'
import CompaniaSeguros from './seguros/CompaniaSeguros.vue'
import CompaniaBaremos from './baremos/CompaniaBaremos.vue'
import CompanyiasService from '../../../services/CompanyiasService.js'
export default {
  components: {
    'compania-datos': CompaniaDatos,
    'compania-sistema': CompaniaSistema,
    'compania-especialidades': CompaniaEspecialidades,
    'compania-facturacion': CompaniaFacturacion, 
    'compania-centralita': CompaniaCentralita, 
    'compania-emails': CompaniaEmails,
    'compania-tramitadores': CompaniaTramitadores,
    'compania-guardias': CompaniaGuardias,
    'compania-slas': CompaniaSlas,
    'compania-seguros': CompaniaSeguros,
    'compania-baremos': CompaniaBaremos
  },
  provide() {
      return {
          agregarProvincia: this.agregarProvincia,
          eliminarProvincia: this.eliminarProvincia,
          agregarSerie: this.agregarSerie,
          eliminarSerie: this.eliminarSerie,
          agregarEspecialidad: this.agregarEspecialidad,
          actualizarEspecialidad: this.actualizarEspecialidad,
          eliminarEspecialidad: this.eliminarEspecialidad,
          guardar: this.guardar,
          guardarCompanyiaSeguros: this.guardarCompanyiaSeguros,
          eliminarCompanyiaSeguros: this.eliminarCompanyiaSeguros,
          obtenerContratosCompanyiaSeguros: this.obtenerContratosCompanyiaSeguros,
          obtenerEspecialidadesGuardias: this.obtenerEspecialidadesGuardias,
          agregarEspecialidadGuardias: this.agregarEspecialidadGuardias,
          eliminarEspecialidadGuardias: this.eliminarEspecialidadGuardias,
          agregarEmailCompanyia: this.agregarEmailCompanyia,
          eliminarEmailCompanyia: this.eliminarEmailCompanyia,
      }
  },
  props: {
      id: {
          default: 0,
      }
  },
  data() {
      return {
          datos: null,
          titulo: null,
          prefijo: null,
          compania:[],
          baremosact:false
      }
  },
  computed: {
      editandoCompanyia() {
          return this.id !== 0;
      },
      numExpedientesActivos() {
          return this.datos.datos?.encurso || 0;
      },
      numExpedientesFacturar() {
          return this.datos.datos?.facturar || 0;
      },
      numExpedientesFinalizados() {
          return this.datos.datos?.finalizados || 0;
      },
  },
  methods: {
    async comprobarBaremos(){
            const api = new PwgsApi();
            const resp = await api.get('parametros-pwgs/gestion_baremos');
            console.log('respi',resp.gestion_baremos);
            if(resp.gestion_baremos==1){
                console.log('entroo');
                this.baremosact = true;
            }
        },
      async cargarDatos() {
          try {
            // Obtener datos cía
            this.comprobarBaremos();
            this.$store.dispatch('cambiarCargando', true);
              const datos = await this.obtenerDatosCompanyia();
              this.compania = datos;
              let ventana = '';
              let ventanaid = this.$props.id.toString();
              let ventanas = this.$store.getters.ventanas;
              let nombreservicio = this.compania.datos.prefijo
              for (let i = 0; i < ventanas.length; i++) {
                  ventana = this.$store.getters.ventanas[i].path;
                  if (ventana == '/companias/' + ventanaid) {
                      this.$store.getters.ventanas[i].name = nombreservicio;
                      this.$store.getters.ventanas[i].alt = "Compañía: " + this.compania.datos.nombre;
                  }
              }
            this.$store.dispatch('cambiarCargando', false);
            return datos;
          }
          catch(error) {
              this.$toast.add({severity:'error', summary: 'PaError', detail: error, life: 5000});
              this.cargando = false;
          }
      },
      async guardar(datos) {
          try {
            this.$store.dispatch('cambiarGuardando', true);
            const companyiasService = new CompanyiasService();
            const body = datos
            let res;
            if (this.id > 0) {
                res = await companyiasService.putCompanyia(this.id, body);
                this.$store.dispatch('cambiarGuardando', false);
                await this.cargarDatos();
            }
            else {
                res = await companyiasService.postCompanyia(body);
                this.$router.push('/companias/' + res.id);
            }    
            this.$toast.add({severity:'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000});
            return res;
          }
          catch (error) {
              this.$toast.add({severity:'error', summary: 'PiError', detail: error, life: 5000});
          }
      },
      async obtenerDatosCompanyia() {
          const id = this.id;
          
          const companyiasService = new CompanyiasService();
          let datos = {};
          if (this.editandoCompanyia) {
            datos = await companyiasService.getCompanyia(id);
            
            datos.provincias = await companyiasService.getProvinciasCompanyia(id);
            datos.series = await companyiasService.getSeriesCompanyia(id);
            datos.especialidades = await companyiasService.getEspecialidadesCompanyia(id);
            datos.seguros = await companyiasService.getCompanyiasSeguros(id);
            datos.especialidadesGuardias = await companyiasService.getEspecialidadesGuardias(id);
            datos.emails = await companyiasService.getEmailsCompanyia(id);
            // NO FUNCIONA BIEN REVISAR -PABLO- Cargamos en paralelo los contratos de cada cía de seguros, pero esperamos a tenerlos de todas, para continuar
        /*     await Promise.all(
                        datos.seguros.map( async (ciaSeguro) => 
                        {
                         const contratos = await this.obtenerContratosCompanyiaSeguros(ciaSeguro.id_companyia_asitur);
                         ciaSeguro.contratos = contratos;
                         }
            ));*/
            this.datos = datos;
            this.titulo = this.datos.datos.nombre;
            this.prefijo = this.datos.datos.prefijo;
          }  
          return datos;
      },
      async agregarProvincia(idProvincia) {
          const id = this.id;
          const companyiasService = new CompanyiasService();
          const res = await companyiasService.postProvinciaCompanyia(id, idProvincia);
          await this.cargarDatos();
          return res;
      },
      async eliminarProvincia(idProvincia) {
          const id = this.id;
          const companyiasService = new CompanyiasService();
          const res = await companyiasService.deleteProvinciaCompanyia(id, idProvincia);
          await this.cargarDatos();
          return res;
      },
      async agregarSerie(idSerie) {
          const id = this.id;
          const companyiasService = new CompanyiasService();
          const res = await companyiasService.postSerieCompanyia(id, idSerie);
          await this.cargarDatos();
          return res;
      },
      async eliminarSerie(idSerie) {
          const id = this.id;
          const companyiasService = new CompanyiasService();
          const res = await companyiasService.deleteSerieCompanyia(id, idSerie);
          await this.cargarDatos();
          return res;
      },
      async agregarEspecialidad(idEspecialidad) {
          const id = this.id;
          const companyiasService = new CompanyiasService()
          const res = await companyiasService.postEspecialidadCompanyia(id, idEspecialidad);
          await this.cargarDatos();
          return res;
      },
      async eliminarEspecialidad(idEspecialidad) {
          const id = this.id;
          const companyiasService = new CompanyiasService()
          const res = await companyiasService.deleteEspecialidadCompanyia(id, idEspecialidad);
          await this.cargarDatos();
          return res;
      },
      async actualizarEspecialidad(idEspecialidad, datos) {
          const id = this.id;
          const companyiasService = new CompanyiasService()
          const res = await companyiasService.putEspecialidadCompanyia(id, idEspecialidad, datos);
          return res;
      },
      async guardarCompanyiaSeguros(datos) {
          const id = this.id;
          const companyiasService = new CompanyiasService()
          this.$store.dispatch('cambiarGuardando', true);
          let res = null;
          if (datos.id_companyia_asitur) {
              const idCiaAsitur = datos.id_companyia_asitur;
              delete datos.id_companyia_asitur;
              await companyiasService.putCompanyiaSeguros(id, idCiaAsitur, datos);
              res = {
                  id: idCiaAsitur
              };
          }
          else {
              res = await companyiasService.postCompanyiaSeguros(id, datos);
          }
          this.$store.dispatch('cambiarGuardando', false);
          const datosCargados = await this.cargarDatos(); 
          console.log(datosCargados);
          return res;
      },
      async eliminarCompanyiaSeguros(idCiaSeguros) {
          const id = this.id;
          const companyiasService = new CompanyiasService()
          this.$store.dispatch('cambiarGuardando', true);
          const res = await companyiasService.deleteCompanyiaSeguros(id, idCiaSeguros);
          this.$store.dispatch('cambiarGuardando', false);
          await this.cargarDatos();
          return res;
      },
      async obtenerContratosCompanyiaSeguros(idCiaSeguros) {
          const id = this.id;
          const companyiasService = new CompanyiasService();
          return await companyiasService.getContratosCompanyiaSeguros(id, idCiaSeguros);
      },
      /**
       * Obtiene las especialidades donde se ha asignado porcentaje por guardia en la compañía
       */
      /*async obtenerEspecialidadesGuardias() {
          const id = this.id;
          const companyiasService = new CompanyiasService();
          return await companyiasService.getEspecialidadesGuardias(id);
      },*/
      async agregarEspecialidadGuardias(idEspecialidad, incremento) {
          const companyiasService = new CompanyiasService();
          const res = await companyiasService.postEspecialidadesGuardias(this.id, {
            id_especialidad: idEspecialidad,
            incremento: incremento,
          });
          await this.cargarDatos();
          return res;
      },
      async eliminarEspecialidadGuardias(idEspecialidad) {
          const companyiasService = new CompanyiasService();
          const res = await companyiasService.deleteEspecialidadGuardias(this.id, idEspecialidad);
          await this.cargarDatos();
          return res;
      },
      async agregarEmailCompanyia(datos) {
          const companyiasService = new CompanyiasService();
          const res = await companyiasService.postEmailCompanyia(this.id, datos);
          await this.cargarDatos();
          return res;
      },
      async eliminarEmailCompanyia(datos) {
          const companyiasService = new CompanyiasService();
          const res = await companyiasService.deleteEmailCompanyia(this.id, datos);
          await this.cargarDatos();
          return res;
      },
  },
  watch: {
      id() {
          this.cargarDatos();
      },
      $route() {
          /**/
          let ventana = '';
          let ventanaid = this.$props.id.toString();
          let ventanas = this.$store.getters.ventanas;
          let nombreservicio = this.compania.datos.prefijo
          for (let i = 0; i < ventanas.length; i++) {
              ventana = this.$store.getters.ventanas[i].path;
              if (ventana == '/companias/' + ventanaid) {
                  this.$store.getters.ventanas[i].name = nombreservicio;
                  this.$store.getters.ventanas[i].alt = "Compañía: " + this.compania.datos.nombre;
              }
          }
          /**/
      }
      
  },
  activated() {
      this.cargarDatos();
  },
 }
</script>
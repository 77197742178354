<template>
    <seccion-datos
        :titulo="remitentes"
        :botonGuardar="false"
        tipo="info"
    >
        <div v-if="mails">
            <div class="row">
                <div class="col-md-5">
                    <div class="input-group" v-if="correosPublicos">                                                
                        <select 
                            class="custom-select form-control-sm"
                            v-model="emailNuevo"
                        >
                            <!--<option
                            v-for="correo in correosPublicos"
                            :key="'mail-remitente-correospublicos-cia-' + this.$route.params.id + '-' + correo.id"
                            :value="correo.id_correo"
                            >{{ nombreCorreo(correo.id_correo) }} ({{ direccionCorreo(correo.id_correo) }})</option>-->
                            <option
                            v-for="correo in correosPublicos"
                            :key="'mail-remitente-correospublicos-cia-' + this.$route.params.id + '-' + correo.id"
                            :value="correo.id_correo"
                            >{{ correo.nombre_correo }} ({{ correo.direccion_correo }})</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="input-group">                                                
                        <select 
                            class="custom-select form-control-sm"
                            v-model="tipoNuevo"
                        >
                            <option 
                                v-for="(tipo, i) in tipos" 
                                :key="'mail-remitente-tipos-cia-' + this.$route.params.id + '-' + i"
                                :value="i"
                            >{{ tipo }}</option> 
                        </select>
                    </div>
                </div>
                <div class="col-md-1">
                    <button 
                        class="btn btn-primary"
                        @click="agregar"
                    >{{ $t('general.anadir') }}</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-sm table-striped">
                        <tbody v-if="correosPublicos && mails">
                            <tr
                                v-for="mail in todosMails"
                                :key="'mail-remitente-cia-' + this.$route.params.id + '-' + mail.tipo"
                                :data-key="'mail-remitente-cia-' + this.$route.params.id + '-' + mail.tipo"
                            >
                                <td>{{ nombreCorreo(mail.id_correo_publico) }} ({{ direccionCorreo(mail.id_correo_publico) }})</td>
                                <td>{{ tipo(mail.tipo) }}</td>
                                <td>
                                    <button 
                                        type="button" 
                                        class="btn btn-block btn-outline-danger btn-xs"
                                        @click="eliminar(mail)"
                                    >{{ $t('general.eliminar') }}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </seccion-datos>
</template>

<script>
export default {
    inject: ['agregarEmailCompanyia', 'eliminarEmailCompanyia'],
    props: {
        mails: {
            type: Array,
        },
        tipos: {
            type: Object,
        }
    },
    data() {
        return {
            correosPublicos: [],
            emailNuevo: null,
            tipoNuevo: null,
            remitentes:'Remitentes'
        }
    },
    computed: {
        todosMails() {
            return this.mails || [];
        }
    },
    methods: {
        nombreCorreo(idCorreo) {
            const correo = this.correosPublicos.find(correoPublico => +correoPublico.id_correo === +idCorreo);
            return correo?.nombre_correo;
        },
        direccionCorreo(idCorreo) {
            const correo = this.correosPublicos.find(correoPublico => +correoPublico.id_correo === +idCorreo);
            return correo?.direccion_correo;
        },
        tipo(idTipo) {
            return this.tipos[idTipo];
        },
        async agregar() {
            const datos = {
                email: this.emailNuevo,
                id_tipo: this.tipoNuevo, 
                remitente: 1
            };
            return await this.agregarEmailCompanyia(datos);
        },
        async eliminar(mail) {
            if (! confirm('Desea quitar este email?')) {
                return false;
            }
            const datos = {
                id_correo: mail.id,
                tipo: mail.tipo,
                remitente: 1,
            }
            return await this.eliminarEmailCompanyia(datos);
        }
    },
    created() {
        this.correosPublicos = this.$store.getters['datosApp/correos'];
        this.remitentes = this.$t('general.remitentes');
    }
}
</script>

<style scoped>
table {
    margin-top: 10px;
}
</style>
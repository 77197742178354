<template>
    <div class="row">
        <div class="col-md-6" v-if="mailsDestinatarios">
            <!-- Inicio Destiniatarios -->
            <companias-emails-destinatarios
            :mails="mailsDestinatarios"
            :tipos="destinatarios_tipos"
            />
            <!-- Fin Destiniatarios -->
        </div>
        <div class="col-md-6" v-if="mailsRemitentes">
            <!-- Inicio Remitentes -->
            <companias-emails-remitentes
            :mails="mailsRemitentes"
            :tipos="remitentes_tipos"
            />
            <!-- Fin Remitentes -->
        </div>
       
    </div>
</template>

<script>
import CompaniasEmailsDestinatarios from './secciones/CompaniaEmailsDestinatarios.vue'
import CompaniasEmailsRemitentes from './secciones/CompaniaEmailsRemitentes.vue'

export default {
  components: {
      CompaniasEmailsDestinatarios,
      CompaniasEmailsRemitentes,
  },
  props: {
      mails: {
          type: Object
      }
  },
  data() {
      return {
          mailsDestinatarios: [],
          mailsRemitentes: [],
          remitentes_tipos: [],
          destinatarios_tipos: [],
      }
  },
  watch: {
      mails(mails) {
          this.mailsDestinatarios = mails.destinatarios;
          this.mailsRemitentes = mails.remitentes;
          this.remitentes_tipos = mails.remitentes_tipos;
          this.destinatarios_tipos = mails.destinatarios_tipos;
      }
  },
}
</script>